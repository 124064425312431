import React from 'react'
import Layout from '../../components/layout'
import PortfolioHeader from '../../components/portfolio-header'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Timeline from '../../components/timeline'
import Recommendation from '../../components/recommendation'
import ScrollTop from '../../components/scroll-top'

import derbyVideoMp4 from '../../images/screenshots/myh/derby-1200-opt.mp4'
import derbyVideoOgv from '../../images/screenshots/myh/derby-1200-opt.ogv'
import derbyVideoWebm from '../../images/screenshots/myh/derby-1200-opt.webm'
import followingVideoMp4 from '../../images/screenshots/myh/following-widget-opt.mp4'
import followingVideoOgv from '../../images/screenshots/myh/following-widget-opt.ogv'
import followingVideoWebm from '../../images/screenshots/myh/following-widget-opt.webm'
import snapVideoMp4 from '../../images/screenshots/myh/snap-program-setup-opt.mp4'
import snapVideoOgv from '../../images/screenshots/myh/snap-program-setup-opt.ogv'
import snapVideoWebm from '../../images/screenshots/myh/snap-program-setup-opt.webm'

const MeYouHealthPage = (props) => (
  <Layout>
    <Timeline />
    <article className='Portfolio'>
      <section className='Portfolio__intro'>
        <PortfolioHeader
          name='MeYou Health'
          position='Front-end Developer'
          startDate='2010-05'
          endDate='2018-10-31'
          logo={props.data.companyLogo.childImageSharp.gatsbyImageData}
        />
        <p className='CompanyIntro'>
          MeYou Health was a well-being company, founded in 2009, with the goal of modernizing traditional health and wellness programs through new and innovative approaches. We created digital and social well-being products that encompassed both the individual and the broader community they lived in. It grew into a platform, showcasing products that achieved sustained engagement and measurable, clinically demonstrated impact.
        </p>
      </section>
      <section className='Portfolio__role'>
        <h2 className='SectionTitle'>My role</h2>
        <p>
          I joined the MeYou Health team as their first front-end developer. I was responsible for building out and maintaining the front-end frameworks, addressing a full spectrum of needs from guiding technology choices to implementing designs. Assisting with design, user experience, and product direction were also core aspects of the role.
        </p>
        <p>
          As part of an agile product team, daily collaboration with design, engineering, research and others was a must. As a team, and as a contributor, we built an array products from ideation through launch.
        </p>
        <h2 className='SectionTitle'>Details</h2>
        <div className='Tags'>
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
          </ul>
          <ul>
            <li>Haml</li>
            <li>Sass</li>
            <li>CoffeeScript</li>
          </ul>
          <ul>
            <li>Ruby on Rails</li>
            <li>Ember</li>
          </ul>
          <ul>
            <li>Bootstrap</li>
            <li>Zurb Foundation</li>
            <li>Semantic UI</li>
          </ul>
        </div>
        <ul className='List'>
          <li>
            Involved in nearly all MeYou Health products, including, but not limited to: <span className='nowrap'>Daily Challenge</span>, Walkadoo, Quitnet, <span className='nowrap'>Well-Being Tracker</span>, Snap, and others
          </li>
          <li>
            Led, built, and maintained multiple front-end frameworks
          </li>
          <li>
            Ensured applications were cross-browser compatible and responsive for a wide range of devices (desktop, tablet, mobile)
          </li>
          <li>
            Leveraged pre and post CSS processing for efficiency
          </li>
          <li>
            Established front-end guidelines, standards, and best practices
          </li>
          <li>
            Produced clean, reusable, and maintainable code
          </li>
          <li>
            Continuously learned and expanded skillsets, particularly within Ruby&nbsp;on&nbsp;Rails and Ember
          </li>
          <li>
            Wrote unit and acceptance tests
          </li>
          <li>
            Utilized metric tracking, A/B testing, usability testing, and user feedback
          </li>
          <li>
            Templated and tested emails for a high volume email system
          </li>
          <li>
            Handled image optimization, SVG cleanup, custom fonts, and dynamic <span className='nowrap'>HTML to PDF</span> generations
          </li>
          <li>
            Integrated with third-party support products, such as Zendesk
          </li>
          <li>
            Themed content management systems, including Wordpress, Squarespace, and others for corporate sites
          </li>
          <li>
            Aided in hiring of additional designers and front-end developers
          </li>
        </ul>
        <h2 className='SectionTitle'>Other technologies & tools</h2>
        <p>
          Git, Github, Adobe products (Photoshop, Illustrator, etc.), Sketch, Sublime Text, Terminal, Xcode Simulator, Android Emulator
        </p>
      </section>
      <section className='Portfolio__recommendations'>
        <h2 className='SectionTitle'>Recommendations</h2>
        <Recommendation name='Emir Bukva' date='2018' position='VP Design'>
          I’ve worked with Collin for over seven years as both a peer and a manager. Besides the CTO, I can’t think of another engineer at MeYou&nbsp;Health who has touched as many different products and codebases as Collin has over those years (Daily Challenge, Walkadoo, MeYou&nbsp;Health website, MeYou&nbsp;Health&nbsp;Account, MeYou&nbsp;Health&nbsp;Survey, Snap). His contributions are both deep and attentive to detail. If it’s one of my designs that he is implementing, I’ve grown to trust his intuitions around spacing, consistency, discoverability and comprehension of interface elements more than my own.
          <br/><br/>
          I’ve asked for Collin’s help with design tasks as well. His work ethic is impeccable. As a professional, he is flexible and friendly. I avoid facilitating design meetings if Collin can’t attend because I value his design opinions. I can recall a number of meetings in which the team was convinced they’ve enumerated all of potential pitfalls, for Collin to identify a major issue that should have been obvious from the start. Collin is a person I’d love to work alongside with, any time.
        </Recommendation>
      </section>
      <section className='Portfolio__examples'>
        <GatsbyImage
          className='ProductLogos'
          image={props.data.productLogos.childImageSharp.gatsbyImageData}
          alt=''
        />
        <h2 className='SectionTitle SectionTitle__gallery'>Work examples</h2>
        <div className='Gallery'>
          <div className='Gallery__item Gallery__item--compare'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.homepageScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item Gallery__item--col-4'>
            <GatsbyImage
              className='GalleryImg GalleryImg--mobile'
              image={props.data.homepageMobileScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item Gallery__item--compare'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.careersScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item Gallery__item--col-4'>
            <GatsbyImage
              className='GalleryImg GalleryImg--mobile'
              image={props.data.careersMobileScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.snapSetupTwoScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.snapSetupThreeScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.snapSetupFourScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <video className='GalleryImg' controls loop playsInline muted={true} autoPlay={true} controlsList='nodownload'>
              <source src={snapVideoWebm} type='video/webm' />
              <source src={snapVideoMp4} type='video/mp4' />
              <source src={snapVideoOgv} type='video/ogg' />
              Your browser doesn’t support HTML5 video tag.
            </video>
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wbidSignupScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wbidOnboardingScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wbidEmailOneScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wbidEmailTwoScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wdHomepageScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wdOnboardingScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wdDerbyIndexScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wdDerbyScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
            <video className='GalleryImg' controls loop playsInline muted={true} autoPlay={true} controlsList='nodownload'>
              <source src={derbyVideoMp4} type='video/mp4' />
              <source src={derbyVideoOgv} type='video/ogg' />
              <source src={derbyVideoWebm} type='video/webm' />
              Your browser doesn’t support HTML5 video tag.
            </video>
          </div>
          <div className='Gallery__item Gallery__item--compare'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wdFeedScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item Gallery__item--col-4'>
            <GatsbyImage
              className='GalleryImg GalleryImg--mobile'
              image={props.data.wdFeedMobileScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
            <video className='GalleryImg GalleryImg--mobile' controls loop playsInline muted={true} autoPlay={true} controlsList='nodownload'>
              <source src={followingVideoMp4} type='video/mp4' />
              <source src={followingVideoOgv} type='video/ogg' />
              <source src={followingVideoWebm} type='video/webm' />
              Your browser doesn’t support HTML5 video tag.
            </video>
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wdEmailDailyScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.wdEmailStampScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.dcOldHomepageScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
            <GatsbyImage
              className='GalleryImg'
              image={props.data.dcOldProfileScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.dcOldFeedScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.dcOldTracksScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.dcOldTrackScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.dcNewLoadingScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.dcNewFeedScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
        </div>
      </section>
      <ScrollTop />
    </article>
    <Timeline />
  </Layout>
)

export default MeYouHealthPage

export const pageQuery = graphql`
  query {
    companyLogo: file(relativePath: { eq: "logos/meyouhealth.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 200
        )
      }
    },
    productLogos: file(relativePath: { eq: "logos/myh/products.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 400,
          placeholder: BLURRED
        )
      }
    },
    homepageScreenshot: file(relativePath: { eq: "screenshots/myh/01-myh-01-homepage.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    homepageMobileScreenshot: file(relativePath: { eq: "screenshots/myh/01-myh-02-homepage-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 300,
          placeholder: BLURRED
        )
      }
    },
    careersScreenshot: file(relativePath: { eq: "screenshots/myh/01-myh-03-careers.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    careersMobileScreenshot: file(relativePath: { eq: "screenshots/myh/01-myh-04-careers-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 300,
          placeholder: BLURRED
        )
      }
    },
    snapSetupOneScreenshot: file(relativePath: { eq: "screenshots/myh/02-snap-01-setup.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    snapSetupTwoScreenshot: file(relativePath: { eq: "screenshots/myh/02-snap-02-setup.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    snapSetupThreeScreenshot: file(relativePath: { eq: "screenshots/myh/02-snap-03-setup.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    snapSetupFourScreenshot: file(relativePath: { eq: "screenshots/myh/02-snap-04-setup.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wbidSignupScreenshot: file(relativePath: { eq: "screenshots/myh/03-wbid-02-signup.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wbidOnboardingScreenshot: file(relativePath: { eq: "screenshots/myh/03-wbid-03-onboarding.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wbidEmailOneScreenshot: file(relativePath: { eq: "screenshots/myh/03-wbid-04-email.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wbidEmailTwoScreenshot: file(relativePath: { eq: "screenshots/myh/03-wbid-05-email.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdHomepageScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-01-homepage.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdOnboardingScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-02-onboarding.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdFeedScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-03-feed.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdFeedMobileScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-04-feed-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdDerbyIndexScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-05-derby-index.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdDerbyScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-06-derby.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdEmailDailyScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-07-email-daily.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    wdEmailStampScreenshot: file(relativePath: { eq: "screenshots/myh/04-wd-08-email-stamp.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dcOldHomepageScreenshot: file(relativePath: { eq: "screenshots/myh/05-dc-01-old-homepage.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dcOldFeedScreenshot: file(relativePath: { eq: "screenshots/myh/05-dc-02-old-feed.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dcOldProfileScreenshot: file(relativePath: { eq: "screenshots/myh/05-dc-03-old-profile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dcOldTracksScreenshot: file(relativePath: { eq: "screenshots/myh/05-dc-04-old-tracks.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dcOldTrackScreenshot: file(relativePath: { eq: "screenshots/myh/05-dc-05-old-track.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dcNewLoadingScreenshot: file(relativePath: { eq: "screenshots/myh/05-dc-06-new-loading.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dcNewFeedScreenshot: file(relativePath: { eq: "screenshots/myh/06-dc-07-new-feed.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    }
  }
`
